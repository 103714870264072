<template>
    <v-main style="min-height: 80% !important">
        <v-container class="pb-12">
            <v-row>
                <v-col cols="12" class="py-0">
                    <h2
                        :style="{ color: parameters.primaryColor }"
                        class="text-left mb-3"
                        :class="$mq != 'lg' ? 'mt-3' : 'mt-6'"
                    >
                        Produtos cadastrados
                    </h2>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="managmentProduct.search"
                        append-icon="mdi-magnify"
                        label="Código do produto e descrição"
                        placeholder="Ex.: 01001; Camisa amarela...."
                        single-line
                        outlined
                        hide-details
                        dense
                        :color="parameters.primaryColor"
                        @click:append="search"
                        @keydown="keydown"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0">
                    <ProductList/>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
const ProductList = () =>
    import(
        /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "@/views/manager/product/ProductList.vue"
    );
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        managmentProduct: {
            get() {
                return this.$store.state.managmentProduct;
            },
        },
    },
    components:{
        ProductList
    },
    methods:{
        keydown(event){
            if (event.keyCode === 13 && !event.shiftKey) {
                this.search()
                event.preventDefault();
            }
        },
        search(){
            this.$store.dispatch("managmentProduct/findList", { search: this.managmentProduct.search });
        }
    },
};
</script>

<style>
</style>